<template>
  <Teleport to="#sidepanel-port">
    <div
      v-show="isSidepanelShown || isTransitionInProgress"
      :id="`sidepanel--${name}`"
      :class="['sidepanel', `sidepanel--${name}`]"
    >
      <transition name="fade">
        <div
          v-if="isSidepanelShown"
          class="sidepanel__overlay"
          @click.stop="hide"
        />
      </transition>

      <transition
        name="slide-from-right"
        @before-enter="onTransitionAppear"
        @after-leave="onTransitionEnd"
      >
        <div v-if="isSidepanelShown" class="sidepanel__contents">
          <div class="sidepanel__scrollable">
            <div class="sidepanel__header">
              <slot name="navigation" :close-sidepanel="hide" />
              <OsButton
                id="close-button"
                tabindex="0"
                :theme="EButtonThemes.close"
                :has-animation="false"
                @click.prevent="hide"
              >
                <OsIcon name="Close" class="text-blue-600" />
                Close
              </OsButton>
            </div>
            <div class="sidepanel__contents__wrap">
              <slot :close-sidepanel="hide" />
            </div>
          </div>
        </div>
      </transition>
    </div>
  </Teleport>
</template>

<script lang="ts">
import { EButtonThemes } from '~/components/base/Button.vue'

export default {
  name: 'LayoutSidepanel',
  props: {
    name: {
      type: String,
      required: true
    },
    modelValue: {
      type: Boolean,
      required: true
    }
  },

  emits: ['close', 'update:modelValue'],

  setup(props, { emit }) {
    const { $dataLayer } = useNuxtApp()

    const isTransitionInProgress = ref(false)

    const isSidepanelShown = computed<boolean>({
      get() {
        return props.modelValue
      },
      set(val: boolean) {
        emit('update:modelValue', val)
      }
    })

    watch(isSidepanelShown, (val) => {
      if (!val) return

      // focus close button when the sidepanel is open
      nextTick(() => {
        const close = document.getElementById('close-button')
        close && close.focus()
      })
    })

    const hide = () => {
      isSidepanelShown.value = false

      $dataLayer.linkClick({
        action: 'close',
        category: props.name
      })

      emit('close')
    }

    const { addOverlayScrollBlocked, removeOverlayScrollBlocked } = useUi()

    const onTransitionAppear = () => {
      isTransitionInProgress.value = true
      addOverlayScrollBlocked()
    }

    const onTransitionEnd = () => {
      isTransitionInProgress.value = false
      removeOverlayScrollBlocked()
    }

    return {
      isTransitionInProgress,
      onTransitionAppear,
      onTransitionEnd,
      isSidepanelShown,
      hide,
      EButtonThemes
    }
  }
}
</script>

<style lang="scss">
.sidepanel-port {
  contain: layout;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  display: contents;
}
</style>
<style lang="scss" scoped>
.sidepanel {
  z-index: 30; // Place panel above back-to-top button.
  transform: translateZ(1px);
  display: flex;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;

  &__overlay {
    pointer-events: auto;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: inherit;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }

  &__contents {
    pointer-events: auto;
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100vw;
    background-color: $white;
    // overflow: auto;

    @include viewport('lg') {
      left: 50vw;
      width: 50vw;
    }
  }
  &--forms {
    z-index: 30;
  }
}

.sidepanel__scrollable {
  overflow-y: auto;
  height: 100%;
  scrollbar-gutter: stable both-edges;
}

.sidepanel__header {
  z-index: 25;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  height: rem(48);
  background-color: white;
  box-shadow: $shadowHeader;

  &:deep(.closeButton) {
    display: flex;
    margin-left: auto;
  }

  &:deep(.os-button) {
    min-width: auto;
    font-size: rem(16);
    padding: 0 rem(16) 0 rem(16);
  }
}

.sidepanel__contents__wrap {
  margin: 0 auto;
  max-width: 400px;
  padding: rem(16) rem(16) rem(64);

  @include viewport('sm') {
    padding: rem(40) rem(16) rem(80);
  }

  > * {
    width: 100%;
  }
}
</style>
